import {
  JWebType,
  Publisher,
  EventServicePluginError,
  Subscriber
} from '@clientos/commons-northbound-api/dist/services/JWeb/types';

const JWEB_EVENTS = {
  CLOSE: 'Close',
  JARVIS_FINISHED: 'jarvisEventFinished'
};

const hasEventServicePluginError = (
  subscriber: Subscriber | EventServicePluginError
) => {
  const hasError =
    (subscriber as EventServicePluginError).errorType !== undefined;
  if (hasError) {
    console.error(
      'has event service plugin while dispatch event error',
      (subscriber as EventServicePluginError).errorType
    );
  }
  return hasError;
};

const generateRandomValue = () => {
  const array = new Uint32Array(2);
  crypto.getRandomValues(array);
  return (array[0].toString(36) + array[1].toString(36)).substring(0, 10);
};

const getPublisher = async (jweb: JWebType) => {
  const eventService = jweb.Plugins.EventService;
  const publisherCryptoValue = generateRandomValue();
  const publisherId = `com.hp.hpterms.dispatchevent.publisher-${publisherCryptoValue}`;
  const publisher = await eventService.createPublisher(publisherId);

  if (hasEventServicePluginError(publisher as EventServicePluginError)) {
    console.error(
      'get publisher in dispatch event failed',
      (publisher as EventServicePluginError).errorType
    );
  } else {
    return publisher as Publisher;
  }
};

export const closeServiceInstance = async (jweb: JWebType): Promise<void> => {
  try {
    const publisher = await getPublisher(jweb);

    await publisher.publish(JWEB_EVENTS.JARVIS_FINISHED, {});
    await publisher.publish(JWEB_EVENTS.CLOSE, {});
  } catch (err) {
    console.error(`Error closing service instance:\n${err}`);
  }
};
