import styled from 'styled-components';
import {
  Button,
  CustomImage,
  Checkbox,
  Scrollbar,
  Group,
  Modal
} from '@veneer/core';
import primitives from '@veneer/primitives';
import { Container as sharedContainer } from 'src/shared/styles';

export const Container = styled(sharedContainer)``;

export const Title = styled.p`
  font-size: 40px;
  line-height: 48px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  width: 821px;
`;

export const Image = styled(CustomImage)`
  height: fit-content;
  margin-top: 40px;
`;

export const Subtitle = styled.p`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
  margin-top: 40px;
`;

export const Terms = styled.p`
  font-size: 18px;
  line-height: 24px;
  white-space: pre-line;
`;

export const AgreeCheckbox = styled(Checkbox)`
  font-size: 18px;
  line-height: 24px;
  margin-top: 40px;
`;

export const AgreeButton = styled(Button)`
  && {
    margin-top: 48px;
    margin-left: 50px;
  }
`;

export const RetryButton = styled(Button)`
  && {
    color: ${primitives.default.color.hpBlue7};
  }
`;

export const GroupContainer = styled(Group)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const DownloadButton = styled(Button)`
  && {
    width: 341px;
    margin-top: 37px;
  }
`;

export const ModalDiv = styled.div`
  position: 'relative';
`;

export const ModalContainer = styled(Modal)`
  .vn-modal--content {
    height: 607px;
    width: 716px;
  }
`;

export const ScrollbarContainer = styled(Scrollbar)`
  display: flex;
  overflow: auto,
  flex-direction: column;
  align-items: flex-start;
  white-space: pre-line;
  border: 1px solid #d1d2e4;
  border-radius: ${primitives.layout.cornerRadius2}px;
  padding: 8px;
  font-size: 16px;
  height: 289px;
  width: 597px;
  line-height: 20px;
  color: #525365;
  ul {
    list-style: inside;
    margin-left: 30px;
  }
  ol {
    list-style: upper-roman;
    margin-left: 30px;
  }
`;

export const ContinueButton = styled(Button)`
  width: 341px;
  font-size: 24px;
  && {
    margin-top: 48px;
    margin-left: 50px;
  }
`;
