import React, { useEffect, useState, useReducer } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import * as T from './types';
import { ToastProvider } from '@veneer/core';
import { DependencyManagerProvider } from 'src/contexts/dependencyManager';
import {
  FLOWS,
  FLOW_QUERY_PARAM,
  flowStatesReducer,
  initialFlowStates
} from 'src/utils/flows';
import { FlowType } from 'src/utils/flows/types';
import * as S from './styles';

const Providers: React.FC = () => {
  const [flow, setFlow] = useState(null);
  const [flowStates, dispatch] = useReducer(
    flowStatesReducer,
    initialFlowStates
  );
  const [searchParams] = useSearchParams();

  const updateFlowStates: T.UpdateFlowStatesType = (key, value) => {
    dispatch({ type: 'UPDATE_FLOW_STATE', key, value });
  };

  const outletContext: T.OutletContextType = { updateFlowStates };

  useEffect(() => {
    const id = searchParams.get(FLOW_QUERY_PARAM);
    const matchingFlow = FLOWS.find((flow: FlowType) => flow.id === id);

    if (matchingFlow) {
      setFlow(matchingFlow);
    }
  }, [searchParams]);

  return (
    <DependencyManagerProvider
      flow={flow}
      flowStates={flowStates}
    >
      <ToastProvider>
        <S.Container data-testid="providers-outlet-container">
          <Outlet context={outletContext} />
        </S.Container>
      </ToastProvider>
    </DependencyManagerProvider>
  );
};

export default Providers;
