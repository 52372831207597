import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Checkbox, useToast } from '@veneer/core';
import { useOutletContext } from 'react-router-dom';
import { OutletContextType } from 'src/components/Providers/types';
import CheckLoader from 'src/components/CheckLoader';
import { useCallSuccess } from '@jarvis/react-portal-addons';
import type { Consent } from '@jarvis/web-stratus-client/dist/src/clients/ucde/consent-mgt-svc';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import usePatchConsentsCall from 'src/hooks/usePatchConsentsCall/usePatchConsentsCall';
import useCheckConsentsCall from 'src/hooks/useCheckConsentsCall/useCheckConsentsCall';
import GoogleFlow from './GoogleFlow';
import ExcelFlow from './ExcelFlow';
import * as S from './styles';

const TermsAndConditions: React.FC = () => {
  const {
    flow,
    northboundAPIs: {
      v1: {
        localization: { language }
      }
    },
    translate
  } = useDependencyManagerContext();

  const [hasAgreed, setHasAgreed] = useState<boolean | null>(null);
  const [consentsData, setConsentsData] = useState<Consent[] | null>(null);
  const [checkedWithError, setCheckedWithError] = useState<boolean | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { addToast, removeToast } = useToast();
  const idCounterRef = React.useRef(0);

  const { updateFlowStates } = useOutletContext<OutletContextType>();

  const currentToastId = `consents-toast-${idCounterRef.current - 1}`;

  const handleCheckboxChange = useCallback(() => {
    setIsChecked((prev) => !prev);
    removeToast(currentToastId);
  }, [removeToast, currentToastId]);

  const purposeIdList = useMemo(
    () => flow.consents.map(({ id }) => id),
    [flow]
  );
  const getDefaultLanguage = useMemo(
    () => (language === 'en' ? language : 'en'),
    [language]
  );

  const setConsentsDataAndHasAgreed = useCallback(({ consentList }) => {
    if (!consentList) {
      return;
    }
    const consentsList: Consent[] = consentList;

    const userHasAgreed = consentsList.every(
      (consent: Consent) => consent.state === 'OptedIn'
    );

    setConsentsData(consentsList);
    setHasAgreed(userHasAgreed);
  }, []);

  const continueFlow = useCallback(() => {
    updateFlowStates('consentsChecked', true);
  }, [updateFlowStates]);

  const checkConsentsCall = useCheckConsentsCall({
    language: getDefaultLanguage,
    purposeIdList
  });

  const patchConsentsCall = usePatchConsentsCall();

  useCallSuccess({
    call: checkConsentsCall,
    onSuccess: setConsentsDataAndHasAgreed
  });

  useCallSuccess({
    call: patchConsentsCall,
    onSuccess: continueFlow
  });

  const makeConsentsCall = patchConsentsCall.makeApiCall;
  const makeCheckConsentsCall = checkConsentsCall.makeApiCall;
  const agreeLoading = checkConsentsCall.pending || patchConsentsCall.pending;
  const showTerms = hasAgreed === false || checkedWithError;

  const addErrorToast = useCallback(
    (onRetry) => {
      const toastId = `consents-toast-${idCounterRef.current}`;
      addToast({
        id: toastId,
        type: 'negative',
        text: translate('termsAndConditions.consentError'),
        action: (
          <S.RetryButton
            data-testid="retry-button"
            appearance="ghost"
            small
            onClick={() => {
              removeToast(toastId);
              onRetry();
            }}
          >
            {translate('termsAndConditions.retry')}
          </S.RetryButton>
        )
      });
      idCounterRef.current += 1;
    },
    [addToast, removeToast, translate]
  );

  const patchConsents = useCallback(
    async (consents: Consent[]) => {
      return makeConsentsCall({
        data: consents.map(({ resourceId }) => ({
          consentResourceId: resourceId,
          state: 'OptedIn'
        }))
      });
    },
    [makeConsentsCall]
  );

  const onAgree = useCallback(async () => {
    try {
      if (consentsData) {
        await patchConsents(consentsData);
        return;
      }

      const response = await makeCheckConsentsCall();
      const consents = response.data.consentList;
      await patchConsents(consents);
    } catch {
      addErrorToast(onAgree);
    }
  }, [addErrorToast, makeCheckConsentsCall, consentsData, patchConsents]);

  useEffect(() => {
    if (patchConsentsCall.error) {
      addErrorToast(onAgree);
    }
  }, [addErrorToast, onAgree, patchConsentsCall.error]);

  useEffect(() => {
    if (checkConsentsCall.error) {
      setCheckedWithError(true);
    }
  }, [checkConsentsCall.error]);

  const LoaderContent = useMemo(
    () => (
      <CheckLoader
        title={translate('termsAndConditions.loadingTitle')}
        description={translate('termsAndConditions.checkingStatus')}
      />
    ),
    [translate]
  );

  const FlowComponent = useMemo(() => {
    if (
      flow.validations.currentBrowserChrome &&
      flow.slug == 'hp-print-ai-chrome-flow'
    ) {
      return <GoogleFlow />;
    }
    if (flow.slug == 'hp-print-ai-excel-flow') {
      return <ExcelFlow />;
    }
    return null;
  }, [flow.slug, flow.validations.currentBrowserChrome]);

  const TermsModal = useMemo(
    () => (
      <S.ModalDiv>
        <S.ContinueButton
          data-testid="continue-button"
          onClick={() => setOpen(true)}
        >
          {translate('termsAndConditions.termsModal.continueButton')}
        </S.ContinueButton>
        <S.ModalContainer
          align="start"
          closeButton
          data-testid="terms-modal"
          show={open}
          onClose={() => setOpen(false)}
          expanded
          maxWidth={716}
          footer={
            <S.GroupContainer data-testid="terms-group-container">
              <Checkbox
                data-testid="terms-modal-checkbox"
                onChange={handleCheckboxChange}
                disabled={agreeLoading}
                label={translate('termsAndConditions.termsModal.agreeCheckbox')}
              />
              <S.DownloadButton
                disabled={!isChecked}
                loading={agreeLoading}
                onClick={onAgree}
                data-testid="modal-download-button"
              >
                {translate('termsAndConditions.termsModal.downloadButton')}
              </S.DownloadButton>
            </S.GroupContainer>
          }
          title={translate('termsAndConditions.termsOfService')}
        >
          <S.ScrollbarContainer data-testid="terms-modal-scrollbar">
            <div
              dangerouslySetInnerHTML={{
                __html: translate(flow.terms.latest)
              }}
            />
          </S.ScrollbarContainer>
        </S.ModalContainer>
      </S.ModalDiv>
    ),
    [
      agreeLoading,
      flow.terms.latest,
      handleCheckboxChange,
      isChecked,
      onAgree,
      open,
      translate
    ]
  );

  useEffect(() => {
    if (hasAgreed && !checkedWithError) {
      continueFlow();
    }
  }, [checkedWithError, continueFlow, hasAgreed]);

  return (
    <S.Container data-testid="terms-and-conditions">
      {showTerms && (
        <>
          {FlowComponent}
          {TermsModal}
        </>
      )}
      {!showTerms && LoaderContent}
    </S.Container>
  );
};

export default TermsAndConditions;
