import React from 'react';
import * as S from '../styles';
import ChromeIcon from '../../../assets/images/ChromeIcon.svg';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

const GoogleFlow: React.FC = () => {
  const { translate } = useDependencyManagerContext();

  return (
    <S.Container data-testid="terms-and-conditions-google">
      <S.Title data-testid="title">
        {translate('termsAndConditions.title')}
      </S.Title>
      <S.ImageContainer>
        <S.Image
          data-testid="google-image"
          size={36}
          quietArea={0}
          src={ChromeIcon.toString()}
        />
        <S.TextContainer>
          <S.Subtitle data-testid="google-subtitle">
            {translate('termsAndConditions.googleFlow.hpPrintSubtitle')}
          </S.Subtitle>
          <S.Terms data-testid="google-description">
            {translate('termsAndConditions.googleFlow.description1')}
          </S.Terms>
          <S.Subtitle>
            {translate('termsAndConditions.privacySubtitle')}
          </S.Subtitle>
          <S.Terms
            dangerouslySetInnerHTML={{
              __html: translate('termsAndConditions.privacyDescription', {
                privacyStatementLink: 'https://www.hp.com/privacy'
              })
            }}
          />
        </S.TextContainer>
      </S.ImageContainer>
    </S.Container>
  );
};

export default GoogleFlow;
