import styled from 'styled-components';
import {
  Container as SharedContainer,
  Description as SharedDescription,
  Title as SharedTitle
} from 'src/shared/styles';

export const Container = styled(SharedContainer)`
  gap: 20px;
  padding-bottom: 20px;
`;

export const Title = styled(SharedTitle)`
  margin-bottom: 28px;
`;

export const Description = styled(SharedDescription)`
  white-space: pre-line;
  max-width: 1052px;
`;
