import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { OutletContextType } from 'src/components/Providers/types';
import CheckLoader from 'src/components/CheckLoader';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { GRANT_CHECK_TYPE, useGrants } from '@jarvis/react-portal-addons';
import * as S from './styles';

const CheckGrants: React.FC = () => {
  const {
    flow,
    flowStates,
    translate,
    northboundAPIs: {
      v1: { grants: grantsInterface, navigation }
    }
  } = useDependencyManagerContext();

  const [hasGrants, setHasGrants] = useState<boolean | null>(null);

  const { grantsCall, refreshGrants } = useGrants({
    init: true,
    grantsInterface,
    checkType: GRANT_CHECK_TYPE.INDIVIDUAL,
    grants: flow.grants
  });

  const { updateFlowStates } = useOutletContext<OutletContextType>();

  const updateGrantsCheckedOnFlowStates = useCallback(() => {
    if (!flowStates.grantsChecked) {
      updateFlowStates('grantsChecked', true);
    }
  }, [flowStates.grantsChecked, updateFlowStates]);

  const LoadingGrantsCheckContent = useMemo(
    () => (
      <CheckLoader
        title={translate('checkingEligibility.title')}
        description={translate('checkingEligibility.description')}
      />
    ),
    [translate]
  );

  const NotHavingGrantsContent = useMemo(
    () => (
      <>
        <S.Title data-testid="check-grants-feature-unavailable-title">
          {translate('checkingEligibility.notEligible.title')}
        </S.Title>
        <S.Description data-testid="check-grants-feature-unavailable-description">
          {translate('checkingEligibility.notEligible.description')}
        </S.Description>
      </>
    ),
    [translate]
  );

  const onRetry = useCallback(() => refreshGrants(), [refreshGrants]);

  const GrantsCheckedWithErrorContent = useMemo(
    () => (
      <>
        <S.Title>{translate('checkingEligibility.error.title')}</S.Title>
        <S.ErrorDescription>
          {translate('checkingEligibility.error.description1')}
        </S.ErrorDescription>
        <S.Description>
          {translate('checkingEligibility.error.description2')}
        </S.Description>
        <S.RetryButton
          data-testid="retry-button"
          loading={grantsCall?.pending as boolean}
          onClick={() => onRetry()}
          small
        >
          {translate('checkingEligibility.retry')}
        </S.RetryButton>
      </>
    ),
    [grantsCall?.pending, onRetry, translate]
  );

  useEffect(() => {
    if (grantsCall?.success && grantsCall?.data) {
      setHasGrants(grantsCall.data?.['ws-hp.com/hpPrintAI']);
    }
  }, [grantsCall, grantsCall.data, grantsCall?.success]);

  useEffect(() => {
    if (hasGrants === true) {
      updateGrantsCheckedOnFlowStates();
    }
  }, [
    flow.id,
    flowStates.grantsChecked,
    hasGrants,
    navigation,
    updateGrantsCheckedOnFlowStates
  ]);

  return (
    <>
      <S.Container data-testid="check-user-grants">
        {hasGrants === false && NotHavingGrantsContent}
        {grantsCall?.error && GrantsCheckedWithErrorContent}
      </S.Container>
      {grantsCall?.pending && !grantsCall?.error && LoadingGrantsCheckContent}
    </>
  );
};

export default CheckGrants;
