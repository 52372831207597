import React, { useCallback, useEffect } from 'react';
import { detect } from 'detect-browser';
import { IconCopy, useToast } from '@veneer/core';
import { useOutletContext } from 'react-router-dom';
import { OutletContextType } from 'src/components/Providers/types';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import ChromeIcon from '../../assets/images/ChromeIcon.svg';
import { closeServiceInstance } from 'src/utils/jweb';
import * as S from './styles';

const UnsupportedBrowser: React.FC = () => {
  const browser = detect();
  const { flow, flowStates, jweb, translate } = useDependencyManagerContext();

  const { updateFlowStates } = useOutletContext<OutletContextType>();

  const { addToast } = useToast();
  const isNative = jweb.isNative;

  const copyButtonToastHandler = useCallback(() => {
    addToast({
      text: translate('unsupportedBrowser.toast'),
      type: 'positive',
      timeout: 30,
      id: ''
    });
  }, [addToast, translate]);

  useEffect(() => {
    if (flow.validations?.currentBrowserChrome && browser?.name !== 'chrome') {
      return;
    }

    if (!flowStates.consentsChecked) {
      return;
    }

    updateFlowStates('browserChecked', true);
  }, [
    browser?.name,
    flow.redirectUrl,
    flow.validations?.currentBrowserChrome,
    flowStates.consentsChecked,
    flowStates.grantsChecked,
    updateFlowStates
  ]);

  const onCopy = useCallback(() => {
    const link = flow.redirectUrl;

    try {
      navigator.clipboard.writeText(link);
      copyButtonToastHandler();
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }, [copyButtonToastHandler, flow.redirectUrl]);

  if (browser?.name !== 'chrome') {
    return (
      <S.Container>
        <S.ImageContainer>
          <S.Image
            data-testid="google-image"
            size={36}
            quietArea={0}
            src={ChromeIcon.toString()}
          />
          <S.ContentContainer>
            <S.Title>{translate('unsupportedBrowser.title')}</S.Title>
            <S.Description>
              {translate('unsupportedBrowser.description')}
            </S.Description>
            <S.CopyContainer>
              <S.Url
                href={flow.redirectUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {flow.redirectUrl}
              </S.Url>
              <S.CopyButton
                appearance="ghost"
                data-testid="copy-button"
                leadingIcon={<IconCopy />}
                onClick={onCopy}
              />
            </S.CopyContainer>
            {isNative && (
              <S.DoneButton
                data-testid="done-button"
                onClick={() => closeServiceInstance(jweb)}
              >
                {translate('unsupportedBrowser.doneButton')}
              </S.DoneButton>
            )}
          </S.ContentContainer>
        </S.ImageContainer>
      </S.Container>
    );
  }

  return null;
};

export default UnsupportedBrowser;
