import { useCallback, useMemo } from 'react';
import { ConsentMgtSvcClient } from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import * as T from './types';

const useCheckConsentsCall = ({
  purposeIdList,
  language
}: T.UseCheckConsentsCallProps): T.UseCheckConsentsCall => {
  const {
    northboundAPIs: {
      v1: { app, authProvider }
    }
  } = useDependencyManagerContext();

  const client = useMemo(
    () => new ConsentMgtSvcClient(app.getPortalStack(), authProvider),
    [app, authProvider]
  );
  const fetch = useCallback(async () => {
    return client.listConsents({ data: { purposeIdList, language } });
  }, [client, language, purposeIdList]);

  return useApiCall({
    fetch,
    init: true,
    returnInCall: true
  });
};

export default useCheckConsentsCall;
