import React from 'react';
import { ProgressIndicator } from '@veneer/core';
import * as T from './types';
import * as S from './styles';

const CheckLoader: React.FC<T.CheckLoaderPropsType> = ({
  title,
  description
}) => {
  return (
    <>
      <S.Title>{title}</S.Title>
      <S.Container data-testid="check-loader-container">
        <ProgressIndicator data-testid="check-loader-progress-indicator" />
        <S.Label>{description}</S.Label>
      </S.Container>
    </>
  );
};
export default CheckLoader;
