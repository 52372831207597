import React from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import * as S from './styles';

const PageNotFound: React.FC = () => {
  const { translate } = useDependencyManagerContext();

  return (
    <S.Container data-testid="page-not-found">
      <S.Title>{translate('pageNotFound.title')}</S.Title>
      <S.Description>{translate('pageNotFound.description')}</S.Description>
    </S.Container>
  );
};

export default PageNotFound;
