import { JWebType } from '@clientos/commons-northbound-api/dist/services/JWeb/types';

declare global {
  interface Window {
    JWeb?: JWebType;
  }
}

const getJWeb = (): JWebType => {
  return (window as Window)?.JWeb;
};

export default getJWeb;
