import React from 'react';
import Providers from 'src/components/Providers';
import InitialPage from 'src/components/InitialPage';
import CheckGrants from 'src/components/CheckGrants';
import TermsAndConditions from 'src/components/TermsAndConditions';
import UnsupportedBrowser from 'src/components/UnsupportedBrowser';
import PageNotFound from 'src/components/PageNotFound';
import FlowChecker from 'src/components/FlowChecker';
import StandaloneTerms from 'src/components/StandaloneTerms';
import { PATHS } from './paths';
import SequenceChecker from 'src/components/SequenceChecker';

export const routes = [
  {
    path: PATHS.ROOT,
    element: <Providers />,
    children: [
      {
        path: PATHS.TERMS,
        element: (
          <FlowChecker>
            <SequenceChecker key={PATHS.TERMS}>
              <InitialPage />
            </SequenceChecker>
          </FlowChecker>
        )
      },
      {
        path: PATHS.CHECK_BROWSER,
        element: (
          <FlowChecker>
            <SequenceChecker key={PATHS.CHECK_BROWSER}>
              <UnsupportedBrowser />
            </SequenceChecker>
          </FlowChecker>
        )
      },
      {
        path: PATHS.CHECK_CONSENTS,
        element: (
          <FlowChecker>
            <SequenceChecker key={PATHS.CHECK_CONSENTS}>
              <TermsAndConditions />
            </SequenceChecker>
          </FlowChecker>
        )
      },
      {
        path: PATHS.CHECK_GRANTS,
        element: (
          <FlowChecker>
            <SequenceChecker key={PATHS.CHECK_GRANTS}>
              <CheckGrants />
            </SequenceChecker>
          </FlowChecker>
        )
      },
      {
        path: PATHS.TERMS_ID,
        element: (
          <FlowChecker>
            <StandaloneTerms />
          </FlowChecker>
        )
      },
      {
        path: PATHS.PAGE_NOT_FOUND,
        element: <PageNotFound />
      }
    ]
  }
];
