import styled from 'styled-components';
import { mixins } from '@jarvis/react-portal-addons';
import { Button, CustomImage } from '@veneer/core';
import { Container as sharedContainer } from 'src/shared/styles';

export const Container = styled(sharedContainer)``;

export const Title = styled.p`
  font-size: 24px;
  line-height: 32px;
`;

export const Description = styled.p`
  margin-top: 48px;
  font-size: 18px;
  line-height: 24px;
`;

export const Url = styled.a`
  font-size: 18px;
  line-height: 24px;

  ${mixins.md(`
    max-width: 470px;
  `)}

  ${mixins.lg(`
    max-width: 770px;
    width: 770px;
  `)}
`;

export const DoneButton = styled(Button)`
  width: 341px;
  font-size: 16px;

  && {
    margin-top: 80px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 821px;
  padding-left: 18px;
}
`;

export const CopyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  max-width: 780px;
  gap: 4px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Image = styled(CustomImage)`
  height: fit-content;
  && {
    width: 50px;
  }
`;

export const CopyButton = styled(Button)`
  && {
    align-self: center;
  }
`;
