import styled from 'styled-components';
import {
  Container as SharedContainer,
  Description as sharedDescription,
  Title as SharedTitle
} from 'src/shared/styles';

export const Container = styled(SharedContainer)`
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  margin-top: 30px;
`;

export const Label = styled(sharedDescription)``;

export const Title = styled(SharedTitle)``;
