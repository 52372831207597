import styled from 'styled-components';
import { Container as sharedContainer } from 'src/shared/styles';

export const Container = styled(sharedContainer)`
  gap: 30px;
`;

export const Title = styled.div`
  font-size: 48px;
  line-height: 48px;
  color: black;
`;

export const Body = styled.div`
  font-size: 18px;
  line-height: 28px;
  color: black;
  white-space: pre-line;
  max-width: 780px;
  ul {
    list-style: inside;
    margin-left: 30px;
  }
  ol {
    list-style: upper-roman;
    margin-left: 30px;
  }
`;
