import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routes } from './routes';
import * as T from './types';

// This file is your main component, it's the one that will be rendered by default.
// We recommend to create small components inside the components folder
// and import them here based on your need to make this file easier to understand.
const router = createBrowserRouter(routes);

// This component wraps your main component with the DependencyManagerProvider
// This provider is responsible to manage global dependencies to your component
const App: React.FC<T.AppPropsType> = () => {
  return <RouterProvider router={router} />;
};

export default App;
