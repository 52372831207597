import { useCallback } from 'react';
import { useApiCall } from '@jarvis/react-portal-addons';
import { ConsentMgtSvcClient } from '@jarvis/web-stratus-client';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import * as T from './types';

const usePatchConsentsCall = (): T.UsePatchConsentsCall => {
  const {
    northboundAPIs: {
      v1: { app, authProvider }
    }
  } = useDependencyManagerContext();

  const apiCall = useCallback(
    async ({ data }) => {
      const client = new ConsentMgtSvcClient(
        app.getPortalStack(),
        authProvider
      );

      return client.bulkUpdateConsents({
        data
      });
    },
    [authProvider, app]
  );

  return useApiCall({
    apiCall,
    init: false,
    returnInCall: true
  });
};

export default usePatchConsentsCall;
