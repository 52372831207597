import React, { useEffect, useState } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { PATHS } from 'src/App/paths';
import * as T from './types';

const ldClientId = 'ld-hp-terms-client';
const grantsFeatureFlagOptions: T.GrantsFeatureFlagOptionsType = {
  key: 'hpTermsGrants',
  defaultValue: null
};

const SequenceChecker: React.FC<T.SequenceCheckerPropsType> = ({
  children
}) => {
  const [hasRedirected, setHasRedirected] = useState(false);

  const {
    flow,
    flowStates,
    northboundAPIs: {
      v1: { navigation, featureFlags }
    }
  } = useDependencyManagerContext();

  const hpTermsGrantsFlag = featureFlags?.useReactFeatureFlag?.(
    React,
    ldClientId,
    grantsFeatureFlagOptions
  );

  useEffect(() => {
    if (!flow.grants) {
      return;
    }

    if (hpTermsGrantsFlag !== true) {
      return;
    }

    if (flowStates.grantsChecked) {
      return;
    }

    if (navigation.location.pathname === `/${PATHS.CHECK_GRANTS}`) {
      return;
    }

    if (hasRedirected) {
      return;
    }

    navigation.push(`${PATHS.CHECK_GRANTS}?id=${flow.id}`);
    setHasRedirected(true);
  }, [
    flow.grants,
    flow.id,
    flowStates.grantsChecked,
    hasRedirected,
    hpTermsGrantsFlag,
    navigation
  ]);

  useEffect(() => {
    if (hpTermsGrantsFlag === null) {
      return;
    }

    if (hpTermsGrantsFlag === true && !flowStates.grantsChecked) {
      return;
    }

    if (flowStates.consentsChecked) {
      return;
    }

    if (navigation.location.pathname === `/${PATHS.CHECK_CONSENTS}`) {
      return;
    }

    if (hasRedirected) {
      return;
    }

    navigation.push(`${PATHS.CHECK_CONSENTS}?id=${flow.id}`);
    setHasRedirected(true);
  }, [
    flow.id,
    flowStates.consentsChecked,
    flowStates.grantsChecked,
    hasRedirected,
    hpTermsGrantsFlag,
    navigation
  ]);

  useEffect(() => {
    if (!flowStates.consentsChecked) {
      return;
    }

    if (navigation.location.pathname === `/${PATHS.CHECK_BROWSER}`) {
      return;
    }

    if (hasRedirected) {
      return;
    }

    navigation.push(`${PATHS.CHECK_BROWSER}?id=${flow.id}`);
    setHasRedirected(true);
  }, [
    flow.id,
    flowStates.consentsChecked,
    hasRedirected,
    hpTermsGrantsFlag,
    navigation
  ]);

  useEffect(() => {
    if (!flowStates.browserChecked) {
      return;
    }

    if (hasRedirected) {
      return;
    }

    setHasRedirected(true);
    navigation.redirect(flow.redirectUrl);
  }, [
    flow.redirectUrl,
    flowStates,
    flowStates.browserChecked,
    hasRedirected,
    navigation
  ]);

  const showContent = hpTermsGrantsFlag !== null && !hasRedirected;

  return showContent ? <>{children}</> : null;
};

export default SequenceChecker;
