import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { Button } from '@veneer/core';

import {
  Container as SharedContainer,
  Description as SharedDescription,
  Title as SharedTitle
} from 'src/shared/styles';

export const Container = styled(SharedContainer)`
  gap: ${primitives.layout.size8}px;
`;

export const Title = styled(SharedTitle)``;

export const Description = styled(SharedDescription)`
  max-width: ${primitives.layout.breakpoint1}px;
`;

export const ErrorDescription = styled(SharedDescription)`
  max-width: 622px;
`;

export const RetryButton = styled(Button)`
  height: 48px;
  width: 112px;
`;
