import React from 'react';
import * as S from './styles';

const InitialPage: React.FC = () => {
  return (
    <>
      <S.Container data-testid="initial-page" />
    </>
  );
};

export default InitialPage;
