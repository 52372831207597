import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.p`
  font-size: 18px;
  line-height: 28px;
`;

export const Title = styled.p`
  font-size: 48px;
  line-height: 48px;
`;
