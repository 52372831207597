import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { PATHS } from 'src/App/paths';
import * as S from './styles';

const StandaloneTerms: React.FC = () => {
  const { id } = useParams();
  const {
    flow,
    translate,
    northboundAPIs: {
      v1: { navigation }
    }
  } = useDependencyManagerContext();

  const termsTextId = useMemo(
    () => (id ? flow.terms[id] : null),
    [flow.terms, id]
  );

  const getTerms = useCallback(() => {
    return translate(termsTextId);
  }, [termsTextId, translate]);

  useEffect(() => {
    if (!termsTextId) {
      navigation.push(`${PATHS.TERMS}/latest?id=${flow.id}`);
    }
  }, [termsTextId, navigation, flow.id]);

  if (!termsTextId) {
    return null;
  }

  return (
    <S.Container data-testid="standalone-terms">
      <S.Title>{translate('termsAndConditions.termsOfService')}</S.Title>
      <S.Body
        dangerouslySetInnerHTML={{
          __html: getTerms()
        }}
      ></S.Body>
    </S.Container>
  );
};

export default StandaloneTerms;
