import React, { useEffect } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { PATHS } from 'src/App/paths';
import * as T from './types';

const FlowChecker: React.FC<T.FlowCheckerPropsType> = ({ children }) => {
  const {
    flow,
    northboundAPIs: {
      v1: { navigation }
    }
  } = useDependencyManagerContext();

  useEffect(() => {
    if (!flow) {
      navigation.push(PATHS.PAGE_NOT_FOUND);
    }
  }, [flow, navigation]);

  return flow ? <>{children}</> : null;
};

export default FlowChecker;
